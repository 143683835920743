<template>
  <v-footer
    id="home-footer"
    min-height="72"
  >
        <div class="site-footer__logo">
          <p>© Copyright since 2018</p>
        </div>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
    }),
  }
</script>
<style>
footer.v-footer, .site-footer__logo {
  background-color: #333333!important;
  color: white!important;
}
</style>
